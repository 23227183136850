@use '../../styles/partials/mixins'as *;
@use '../../styles/partials/colors'as *;
.container {
    font-family: 'NotoSans';
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 4rem 0;
    margin-left: 2.5rem;
    // width:100%;
    text-align: center;
    font-size: 1rem;
    @include tablet {
        text-align: left;
        width: 100%;
        display: flex;
        align-items: center;
    }
    &__about {
        width: 100%;
        justify-content: space-around;
        @include tablet {
            line-height: 3rem;
        }
    }
    &__information {
        width: 75%;
        font-size: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include tablet {
           display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
        
    }
    &__logos {
        margin-top: 1rem;
        @include tablet {
        display: flex;
        flex-direction: column;
        margin: 0 0 0 2rem;
        }
    }
    &__name {
        font-size: 1.85rem;
        height: 4rem;
        @include tablet {
            font-size: 3rem;
        }
        @include desktop {
            font-size: 5rem;
            margin: 3rem 0;
        }
    }
    &__text {
        margin-top: 1rem;
        line-height: 1.2rem;
    }
}
.social {
    transition: 2s ease;
    color: $dark-purple;
    border-radius: 25px;
    margin: 1rem;
}

.social:hover {
    transform: scale(1.5);
    cursor: pointer;
    transition: 1.5s;
    }

.cursor {
  background-color: #111;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
}