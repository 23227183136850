@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/colors' as *;

.cursor {
  background-color: #111;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
}

.projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3rem 0;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    @include tablet {
        display: grid;
        place-items: center;
        margin: 3rem 3rem;
    }
    @include desktop {
        margin: 3rem 4rem;
    }
    &__cards {
    text-decoration: none;
    display: flex;
    margin: 5rem 0 2rem 4rem;
    width: 17rem;
    color: $dark-red;
    transition: all 1s ease;
    box-shadow: rgba(255, 214, 214, 0.25) 0px 54px 55px, 
    rgba(0, 0, 0, 0.12) 0px -12px 30px, 
    rgba(0, 0, 0, 0.12) 0px 4px 6px, 
    rgba(255, 255, 255, 0.17) 0px 12px 13px, 
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
    @include tablet {
        width: 20rem;
    }
    
    }
}

.projects__cards:hover {
    transform: scale(1.1);
    z-index: 2;
    transition: 2s;
}
.projects__title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
