$dark-red: #960200ff;
$dark-purple: #242038ff;
$maximum-blue-purple: #a594f9ff;
$selective-yellow: #fcba04ff;
$linen: #f7ece1ff;

$charcoal: #383f51ff;
$lavender-web: #dddbf1ff;
$cedar-chest: #cd533bff;
$titanium-yellow: #f7e733ff;
$pistachio: #8cd867ff;