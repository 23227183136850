@use '../../styles/partials/mixins'as *;
@use '../../styles/partials/colors'as *;

form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    
}
.contact {
    // margin: auto 1rem auto 3rem;
    margin: 3rem 0;
    margin-left: 5.5rem;
    font-family: 'NotoSans';
    color: $maximum-blue-purple;
    display: flex;
    flex-direction: column;
    width: 75%;
    height: 100%;
    @include tablet {
        display: flex;
        justify-content: center;
        margin: 6rem 9rem;
    }
    @include desktop {
        margin: 4rem auto;
        justify-content: center;
    }
}

.contact__title--container {
    display: flex;
    justify-content: center;
} 

.button__container {
    display: flex;
    justify-content: center;
}
.contact__title{
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
}
