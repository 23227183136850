@use './styles/partials/mixins' as *;
@use './styles/partials/fonts'as *;
@use './styles/partials/colors'as*;
@import 'animate.css';

*, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
html {
    background-color: $linen;
    color: $dark-purple;
    font-family: 'NotoSans',Verdana, Geneva, Tahoma, sans-serif;
}

.text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 35%;
    vertical-align: middle;
    display: table-cell;
    max-height: 91%;
  }
