@use '../../styles/partials/fonts'as *;
@use '../../styles/partials/mixins'as*;

.skills{
    // background: linear-gradient(135deg, rgba(255,255,255, 0.1), rgba(255,255,255,0));
    // -webkit-backdrop-filter: blur(20px);
    // backdrop-filter: blur(20px);
    // box-shadow: 0 8px 32px 0 rgba(0,0,0,0.37);
    // border: 1px solid rgba(255,255,255,0.18);
    // border-radius: 32px;
    color: black;
    padding-top: 4rem;
    font-family: 'NotoSans';
    &__container {
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    @include tablet {
        gap: 2.5rem;
        grid-template-columns: repeat(4, 1fr);
    }
    @include desktop {
        // gap: 3rem;
        grid-template-columns: repeat(5, 2fr);
    }
    }
    &__title{
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 1.5rem;
    @include desktop {
        font-size: 2rem;
    }
    }
    &__image{
    object-fit: cover;
    transition: 1s;
    height: 85px;
    @include tablet {
        height: 115px;
    }
    @include desktop {
        height: 175px;
    }
    }
}

.skills__image:hover{
    transform: scale(1.3);
    z-index: 1;
}

// .github{
//     background-color: white;
// }
//fdfd